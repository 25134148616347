/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { Modal } from '../../../../main/Modal';
import { OrderForm } from '../../../../common/OrderForm';
import { KmmSuccesModal } from '../../../kmmPage/screens/KmmSuccesModal';
import { KmmButton } from '../../../kmmPage/components/KmmButton';
import { sendCodeReviewKmmRequest } from '../../../../../utils/api';
import { OopsPanel } from '../../../../common/OopsPanel';

type IProps = {
  onClose: () => void;
  titleType: string;
  formId: string;
};

const KmmReviewModal: FC<IProps> = ({ onClose, titleType, formId }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const prefix = usePathPrefix();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOops, setShowOops] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      if (!executeRecaptcha) return;
      const captcha = await executeRecaptcha(formId);
      const res = await sendCodeReviewKmmRequest({ ...data, captcha });

      if (res.data) {
        setIsModalOpen(true);
      }
    } catch (e) {
      const error = e as AxiosError;
      setErrorMessage(error.response?.data.error);
      setShowOops(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccessClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <>
      <OopsPanel isOpen={showOops} errorMessage={errorMessage} />

      <div className={styles.title}>{t(titleType)}</div>
      <Modal onClose={onClose}>
        <div className={styles.modal__content}>
          <div className={styles.wrap}>
            <div className={styles.modal__close} onClick={onClose}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.439362 0.439339C-0.146454 1.02512 -0.146454 1.97489 0.439362 2.56068L6.87869 9.00001L0.468781 15.4099C-0.117035 15.9957 -0.117035 16.9454 0.468781 17.5312C1.0546 18.117 2.0043 18.117 2.59012 17.5312L9.00003 11.1213L15.2886 17.4099C15.8744 17.9957 16.8241 17.9957 17.4099 17.4099C17.9958 16.8241 17.9958 15.8744 17.4099 15.2886L11.1214 9.00001L17.4394 2.68198C18.0252 2.0962 18.0252 1.14646 17.4394 0.560677C16.8535 -0.0251083 15.9038 -0.0251083 15.318 0.560677L9.00003 6.87867L2.5607 0.439339C1.97488 -0.146446 1.02518 -0.146446 0.439362 0.439339Z"
                  fill="#55439D"
                />
              </svg>
            </div>
            <div className={styles.content} id="form">
              <h2>{titleType}</h2>
              <div className={styles.form}>
                <OrderForm
                  formId={formId}
                  onSubmit={handleSubmit}
                  isLoading={isLoading}
                  isFormSucces={isModalOpen}
                  withSubscribe
                />
              </div>
              <div className={styles.terms}>
                {t('cases.restocare.contact.terms')}{' '}
                <a
                  href={`${prefix}/case/restocare/terms.pdf`}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {t('cases.restocare.contact.personal_data')}
                </a>
              </div>
            </div>

            {isModalOpen && (
              <KmmSuccesModal onClose={onSuccessClose}>
                <div className={styles.result}>
                  <h1>{t('cases.restocare.contact.success_title')}</h1>
                  <div>{t('cases.restocare.contact.success_subtitle')}</div>
                  <KmmButton onClick={onSuccessClose}>OK</KmmButton>
                </div>
              </KmmSuccesModal>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { KmmReviewModal };
