import React, { FC, useCallback, useState } from 'react';
import { PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { IDirectionTagsQuery } from '../../../../queries/directionTags/types';
import { KmmTitle } from '../../../../components/directions/kmmPage/screens/KmmTitle';
import { KmmIntegrate } from '../../../../components/directions/kmmPage/screens/KmmIntegrate';
import { KmmForWhom } from '../../../../components/directions/kmmPage/screens/KmmForWhom';
import { KmmWhatsGive } from '../../../../components/directions/kmmPage/screens/KmmWhatsGive';
import { KmmTransition } from '../../../../components/directions/kmmPage/screens/KmmTransition';
import { KmmDirectionsForm } from '../../../../components/directions/kmmPage/screens/KmmDirectionsForm';
import { KmmWeDidIt } from '../../../../components/directions/kmmPage/screens/KmmWeDidIt';
import { KmmReviewModal } from '../../../../components/directions/kmm/screens/KmmReviewModal';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery,
  { locale: string; tag: string; page: number }
>;

const KmmPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'ru' },
}) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [, setModalTitle] = useState('');

  const toggleModal = useCallback(
    () => setIsModalShown(!isModalShown),
    [isModalShown, setIsModalShown]
  );

  return (
    <ReCaptchaProvider>
      <MainLayout locale={locale} title="Kotlin Multiplatform">
        <KmmTitle title="KMM" />
        <KmmIntegrate />
        <KmmForWhom showModal={toggleModal} setModalTitle={setModalTitle} />
        <KmmWhatsGive />
        <KmmTransition />
        <KmmWeDidIt />
        <KmmDirectionsForm />
        {isModalShown && (
          <KmmReviewModal
            formId="order_form_review_kmm"
            onClose={toggleModal}
            titleType="Закажите бесплатное код-ревью! Мы проанализируем ваш проект и предоставим результат-отчет"
          />
        )}
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export default KmmPage;
