/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { KmmButton } from '../../components/KmmButton';

interface IProps {
  showModal: () => void;
  setModalTitle: (value: string) => void;
}

const KmmForWhom: FC<IProps> = ({ showModal, setModalTitle }) => {
  const prefix = usePathPrefix();
  const kmmImgSrc = '/images/directions/kmm-image.svg';

  const showReviewModal = () => {
    setModalTitle('cases.design.modalTitle');
    showModal();
  };

  const links = [
    'https://kmm.icerock.dev/learning/intro/',
    'https://moko.icerock.dev/',
    'https://kmm.icerock.dev/university/intro/',
    'https://kmm.icerock.dev/onboarding/intro/',
  ];

  return (
    <div className={styles.wrap}>
      <h3>{t('cases.kmmPage.kmmForWhom.subTitle')}</h3>
      <h2>{t('cases.kmmPage.kmmForWhom.title')}</h2>
      <div className={styles.cases}>
        <div className={styles.case}>
          <p>{t('cases.kmmPage.kmmForWhom.cards.card_1.desc')}</p>
          <h4>{t('cases.kmmPage.kmmForWhom.cards.card_1.title')}</h4>
          <div className={styles.subtitle}>
            {t('cases.kmmPage.kmmForWhom.cards.card_1.titleExp')}
          </div>
          <div className={styles.exp}>
            <div className={styles.exp_card}>
              <img
                src={`${prefix}/case/kmmPage/icons/edu_base.svg`}
                alt="education base"
              />
              <a href={links[0]} target="_blank" rel="noreferrer">
                {t('cases.kmmPage.kmmForWhom.cards.card_1.exp_1')}
              </a>
            </div>
            <div className={styles.exp_card}>
              <img
                src={`${prefix}/case/kmmPage/icons/library.svg`}
                alt="education base"
              />
              <a href={links[1]} target="_blank" rel="noreferrer">
                {t('cases.kmmPage.kmmForWhom.cards.card_1.exp_2')}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.case}>
          <p>{t('cases.kmmPage.kmmForWhom.cards.card_2.desc')}</p>
          <h4>{t('cases.kmmPage.kmmForWhom.cards.card_2.title')}</h4>
          <div className={styles.subtitle}>
            {t('cases.kmmPage.kmmForWhom.cards.card_2.titleExp')}
          </div>
          <div className={styles.exp}>
            <div className={styles.exp_card}>
              <img
                src={`${prefix}/case/kmmPage/icons/edu_junior.svg`}
                alt="education base"
              />
              <a href={links[2]} target="_blank" rel="noreferrer">
                {t('cases.kmmPage.kmmForWhom.cards.card_2.exp_1')}
              </a>
            </div>
            <div className={styles.exp_card}>
              <img
                src={`${prefix}/case/kmmPage/icons/edu_senior.svg`}
                alt="education base"
              />
              <a href={links[3]} target="_blank" rel="noreferrer">
                {t('cases.kmmPage.kmmForWhom.cards.card_2.exp_2')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.magnitBlock}>
        <div className={styles.leftSide}>
          <img src={kmmImgSrc} alt="kmm img" />
        </div>
        <div className={styles.rightSide}>
          <h2 className={styles.headText}>
            {t(`cases.kmmPage.kmmForWhom.magnitBlock.title`)}
          </h2>
          <p className={styles.usualText}>
            {t(`cases.kmmPage.kmmForWhom.magnitBlock.line1`)}
          </p>
        </div>
      </div>
      <KmmButton onClick={showReviewModal}>
        {t('cases.kmmPage.kmmForWhom.button')}
      </KmmButton>
    </div>
  );
};
export { KmmForWhom };
